<template>
  <v-container class="d-flex flex-column">
    <v-card v-if="showForm" class="elevation-0">
      <CategoryTitle :category="category" class="mt-0 mb-6 my-lg-6" />
      <div class="text-h4">
        INSERISCI L' EMAIL CON CUI TI SEI REGISTRATO
      </div>
      <v-row no-gutters class="mt-4">
        <v-col cols="12" sm="8">
          <v-card-text class="pa-0">
            <v-form
              v-on:submit.prevent
              ref="form"
              v-model="valid"
              :lazy-validation="lazy"
            >
              <v-text-field
                v-model="email"
                label="Indirizzo email *"
                required
                outlined
                :rules="emailRules"
                dense
                clearable
                @keyup.enter="handleResetPasswordRequest"
              ></v-text-field>
            </v-form>
          </v-card-text>
        </v-col>
        <v-col cols="12" sm="4" class="px-0 px-sm-4">
          <v-card-actions class="pa-0">
            <!-- to="/register" -->
            <v-btn
              @click="handleResetPasswordRequest"
              class="reset-button"
              color="primary"
              large
              :block="$vuetify.breakpoint.xs"
              min-width="200"
              height="40"
              depressed
              :loading="loading"
              :disabled="!valid || email.length <= 0"
              >{{ $t("login.submitResetPassword") }}</v-btn
            >
          </v-card-actions>
        </v-col>
      </v-row>
      <div class="mt-2">
        <ResponseMessage :response="response" />
      </div>
    </v-card>

    <v-card v-if="!showForm" class="elevation-0">
      <div class="d-flex align-center flex-column mb-3">
        <h1 class="reset-title">RECUPERA PASSWORD</h1>
      </div>
      <v-spacer></v-spacer>
      <v-card-text class="response-messages">
        <ResponseMessage :response="response" />
      </v-card-text>
      <v-card-actions>
        <div class="d-flex flex-column align-center mt-2 register-wrapper">
          <!-- <v-btn
                  @click="resetPage"
                  class="reset-button"
                  color="$white"
                  large
                  depressed
                  :loading="loading"
                  :disabled="!valid"
                >
                  {{ $t("common.back") }}
                </v-btn> -->
        </div>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<style scoped lang="scss">
.response-message {
  width: 100%;
}
.response-messages {
  .v-alert {
    &.v-alert--outlined {
      border: none;
    }
  }
}
@media #{map-get($display-breakpoints, 'xs-only')} {
  .register-wrapper {
    width: 100%;
    button {
      width: 100%;
    }
  }
}
</style>
<script>
import RegistrationService from "@/service/poliCustomService";
import ResponseMessage from "@/components/ResponseMessage.vue";
import CategoryTitle from "@/components/category/CategoryTitle";
import { requiredValue, isEmail } from "~/validator/validationRules";
import category from "~/mixins/category";

export default {
  components: { ResponseMessage, CategoryTitle },
  name: "ResetPasswordRequest",
  mixins: [category],
  data() {
    return {
      email: "",
      valid: true,
      loading: false,
      lazy: true,
      emailRules: [requiredValue("Digitare l'e-mail"), isEmail()],
      response: {},
      showForm: true
    };
  },
  methods: {
    async handleResetPasswordRequest(e) {
      let _this = this;
      e.preventDefault();
      _this.response = {};
      if (_this.valid) {
        _this.loading = true;
        try {
          const res = await RegistrationService.recoverPassword(_this.email);
          if (res.response?.status == 0) {
            _this.$router.push({ name: "ResetPasswordSent" });
          } else {
            _this.response = res.response;
          }
        } catch (err) {
          if (err.response) {
            _this.response = err.response.data.response;
          } else {
            _this.response = {
              errors: [{ error: "Email non trovata" }],
              infos: [],
              status: 0
            };
          }
        } finally {
          // _this.showForm = false;
          _this.loading = false;
        }
      }
    },
    resetPage() {
      this.email = "";
      this.response = {};
      this.showForm = true;
    }
  }
};
</script>

<style></style>
